import api from "@/base/utils/request";

// 获取架构树状结构数据
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  });
};

// 保存职务架构
export const savePositionLevel = data => {
  return api({
    url: "/admin/cyc/position/savePositionLevel",
    method: "post",
    data
  });
};
// 保存一级架构
export const sortFirstOrganizations = data => {
  return api({
    url: "/admin/cyc/organization/sortFirstOrganizations",
    method: "post",
    data
  });
};
// 显示或者隐藏架构
export const updateOrganization= data => {
  return api({
    url: "/admin/cyc/organization/updateIsShow",
    method: "post",
    data
  });
};
// 显示或者隐藏职务
export const updatePosition = data => {
  return api({
    url: "/admin/cyc/position/updateIsShow",
    method: "post",
    data
  });
};
// 是否合并子架构同名职务
export const updateShowChildren = data => {
  return api({
    url: "/admin/cyc/organization/updateShowChildren",
    method: "post",
    data
  });
};
// 获取会员职务排序
export const getShowSorts = data => {
  return api({
    url: "/admin/cyc/user_op/getShowSorts",
    method: "post",
    data
  });
};
// 保存会员职务排序
export const updateShowSorts = data => {
  return api({
    url: "/admin/cyc/user_op/updateShowSorts",
    method: "post",
    data
  });
};