<template>
  <div class="member-sort-list">
    <Reminder text="支持拖拽调整职务排序，通过 “编辑会员排序” 调整会员排序" />
    <el-table
      class="thead-light"
      id="sortTable"
      row-key="user_position_id"
      :data="tableData"
      stripe
      style="width: 100%"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-for="th in thead"
        :key="th.prop"
        :prop="th.prop"
        :label="th.label"
        :width="th.width"
        :min-width="th.miniWidth"
        :sortable="th.sortable"
        :fixed="th.fixed"
      >
        <template slot-scope="{ row }">
          <!-- 排序 -->
          <i v-if="th.prop === 'sortable'" class="el-icon-sort"></i>
          <!-- 头像 -->
          <list-image
            v-else-if="th.prop === 'avatar'"
            :src="row[th.prop]"
          ></list-image>
          <!-- 其他 -->
          <span v-else>{{ row[th.prop] | placeholder }}</span>
        </template>
      </el-table-column>
    </el-table>
    <FixedActionBar>
      <el-button @click="cancle">返回</el-button>
    </FixedActionBar>
  </div>
</template>
<script>
import Sortable from 'sortablejs/modular/sortable.core.esm.js'
import Reminder from '@/modules/common/components/Reminder.vue'
import ListImage from '@/base/components/List/ListImage'
import FixedActionBar from '@/base/layout/FixedActionBar'

import {
  getShowSorts,
  updateShowSorts,
} from '../../api/cyc-sorts-config/level-config'
export default {
  data() {
    return {
      // 表头
      thead: [
        { label: '排序', prop: 'sortable', width: 50 },
        { label: '头像', prop: 'avatar', miniWidth: 80 },
        // { label: "id", prop: "user_position_id", miniWidth: 80 },
        { label: '会员名称', prop: 'user_name', miniWidth: 150 },
        { label: '会内职务', prop: 'title_name', miniWidth: 120 },
      ],
      // 职务id
      position_id: -1,
      tableData: [],
    }
  },
  methods: {
    // 点击取消
    cancle() {
      try {
        this.$router.go(-1)
      } catch (err) {
        this.$router.push({ name: 'CycSortsConfigIndex' })
      }
    },
    sortChange() {},
    handleSelectionChange() {},
    // 获取会员列表
    getMemberList() {
      getShowSorts({
        position_id: this.position_id,
      }).then((res) => {
        console.log(res)
        this.tableData = res.data
      })
    },
    //初始化拖拽表格
    initSortable() {
      this.$nextTick(() => {
        const sortTable = document.querySelector(
          '#sortTable .el-table__body>tbody'
        )
        console.log(sortTable)
        this.$sortTable = Sortable.create(sortTable, {
          animation: 150,
          ghostClass: 'sortable-ghost',
          onEnd: (event) => {
            console.log(event.oldIndex, event.newIndex)
            // 拿到拖拽的item
            const oldItem = this.tableData[event.oldIndex]
            // 删除原本位置
            this.tableData.splice(event.oldIndex, 1)
            // 添加到新的位置
            this.tableData.splice(event.newIndex, 0, oldItem)

            const idArr = this.tableData.map((item) => item.user_position_id)
            console.log(this.tableData)
            //保存拖拽排序
            this.saveMemberSort(idArr)
          },
        })
        //阻止火狐拖拽新建新页面
        document.body.addEventListener('drop', this.preventDrop, false)
      })
    },
    // 保存会员排序
    saveMemberSort(idArr) {
      updateShowSorts({
        position_id: this.position_id,
        user_position_ids: idArr,
      }).then((res) => {
        this.$message.success('更新排序成功')
      })
      console.log(idArr)
    },
    //销毁拖拽表格
    destroySortable() {
      if (this.$sortTable) this.$sortTable.destroy()
      document.body.removeEventListener('drop', this.preventDrop, false)
    },
    //阻止默认drop事件
    preventDrop(event) {
      event.preventDefault()
      event.stopPropagation()
    },
  },
  created() {
    // 获取职务id
    this.position_id = this.$route.params.id
    // 获取会员列表
    this.getMemberList()
  },
  mounted() {
    //初始化拖拽表格
    this.initSortable()
  },
  beforeDestroy() {
    //销毁拖拽表格
    this.destroySortable()
  },
  components: { Reminder, ListImage, FixedActionBar },
}
</script>
<style lang="scss" scoped>
.app-list {
}
.comeBack {
  position: absolute;
  right: 100px;
  bottom: 100px;
}
</style>
